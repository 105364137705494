<template>
  <div class="news" :data-page="modalParams.page" :class="{skeleton: !state.loaded.news}">
    <div class="header">
      <div class="top">
        <div class="title font-md">
          <b>{{ state.news.title }}</b>
        </div>
        <div class="acts">
          <template v-if="modalParams.page === 'manage'">
            <button class="btn btn-default border font-sm edit" @click="edit()">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-default border font-sm remove" @click="remove()">
              <i class="fa fa-trash"></i>
            </button>
          </template>
          <router-link class="font-sm" :to="`/${modalParams.projectType}/${modalParams.projectSeq}`" v-else-if="modalParams.page !== 'detail'">프로젝트로 이동</router-link>
        </div>
      </div>
      <div class="desc font-sm">
        <router-link :to="`/channel/${state.news.writerMemberSeq}`">{{ state.news.writerName }}</router-link>
        <span class="float-right">{{ state.news.createDate }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="content edit-style font-sm" v-html="state.news.content"></div>
      <div class="maker-info">
        <MakerInfo :builderSeq="state.news.writerMemberSeq" :projectType="modalParams.projectType"/>
      </div>
      <div class="comments" :class="{skeleton:!state.loaded.comments}">
        <div class="form">
          <div class="title">
            <span>댓글 작성</span>
          </div>
          <div class="input">
          <textarea class="form-control border-focus-point" :placeholder="$store.state.account.loggedIn ? '댓글을 남겨주세요.' : $definitions.messages.needLogin" :readonly="!$store.state.account.loggedIn" @focus="checkLogin()"
                    ref="commentTextareaRef"></textarea>
            <button class="btn btn-secondary border font-sm" @click="postComment()" :disabled="!$store.state.account.loggedIn">등록</button>
          </div>
        </div>
        <ul class="tight" v-if="Array.isArray(state.comments) && state.comments.length">
          <li v-for="(c, idx) in state.comments" :key="`${c.createDate}${c.memberSeq}${idx}`">
            <Comment
                :idx="idx"
                :commentSeq="c.commentSeq"
                :content="c.content"
                :change="loadComments"
                :createDate="c.createDate"
                :depth="Number(c.depth)"
                :edit="editComment"
                :groupNum="c.groupNum"
                :memberSeq="c.memberSeq || c.createId"
                :memberName="c.memberName"
                :memberAuth="c.memberAuth"
                :openId="c.openId"
                page="news"
                :projectType="modalParams.projectType"
                :projectSeq="Number(modalParams.projectSeq)"
                :reply="replyComment"
                :remove="removeComment"
                :skeleton="!state.loaded.comments"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="action" v-if="modalParams.page === 'my'">
      <router-link :to="`/${modalParams.projectType === 'mock' ? 'reward' : modalParams.projectType}/${modalParams.projectSeq}`" class="btn btn-point btn-block font-sm">
        <span>프로젝트로 이동</span>
        <span class="img" style="background-image:url(/assets/ico/common.join.white.svg)"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import MakerInfo from "@/components/MakerInfo";
import Comment from "@/components/Comment.vue";

function Component(initialize) {
  this.name = "modalNews";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, MakerInfo, MemberPicture},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});
      loadNews();
      loadComments();
    });

    const state = reactive({
      loaded: {
        news: false,
        comments: false
      },
      news: {
        title: "Wait a moment",
        content: "Please wait a moment",
        writerMemberSeq: "",
        writerName: "Wait",
        createDate: "0000-00-00 00:00"
      },
      comments: []
    });

    const commentTextareaRef = ref();
    const modalParams = store.getters.modalParams(component);

    const edit = () => {
      store.commit("replaceModal", {
        name: "NewsWrite",
        params: modalParams,
        callback: `${component.name}.loadComments`,
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/news/${modalParams.newsSeq}`).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "삭제하였습니다.");
              }
            });
          });
        }
      });
    };

    const checkLogin = () => {
      !store.state.account.memberSeq && store.dispatch("goLoginPage");
    };

    const loadNews = () => {
      state.loaded.news = false;
      http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news/${modalParams.newsSeq}`).then(({data}) => {
        state.loaded.news = true;
        state.news = data.body;
      });
    };

    const loadComments = () => {
      state.comments = [];

      for (let i = 0; i < 3; i += 1) {
        state.comments.push({
          memberName: "Wait a moment",
          createDate: "0000-00-00 00:00",
          content: "Wait a moment",
        });
      }

      state.loaded.comments = false;
      http.get(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments`, {newsSeq: modalParams.newsSeq}).then(({data}) => {
        state.loaded.comments = true;
        state.comments = data.body;
      });
    };

    const postComment = () => {
      const content = commentTextareaRef.value.value;

      if (!content?.trim()) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        commentTextareaRef.value.focus();
        return;
      }

      const args = {
        newsSeq: modalParams.newsSeq,
        content: content,
        createId: store.state.account.memberSeq
      };

      http.post(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments`, args).then(() => {
        store.dispatch("callback", {component});
        store.commit("setSwingMessage", "댓글을 등록하였습니다.");
        loadComments();
        commentTextareaRef.value.value = "";
      });
    };

    const patchComment = ({commentSeq, content, updateId}) => {
      http.patch(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments/${commentSeq}`, {content, updateId}).then(() => {
        store.commit("setSwingMessage", "댓글을 수정하였습니다.");
        loadComments();
      }).catch(httpError());
    };

    const editComment = (idx) => {
      const comment = state.comments[idx];

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content
        },
        callback: `${component.name}.patchComment`,
      });
    };

    const removeComment = (idx) => {
      const comment = state.comments[idx];

      store.commit("confirm", {
        message: "선택하신 댓글을 삭제하시겠습니까?",
        allow() {
          const params = {
            depth: comment.depth,
            groupNum: comment.groupNum,
            updateId: store.state.account.memberSeq
          };

          http.delete(`/api/${modalParams.projectType}/projects/${modalParams.projectSeq}/news-comments/${comment.commentSeq}`, params).then(() => {
            store.dispatch("callback", {component});
            store.commit("setSwingMessage", "댓글을 삭제하였습니다.");
            loadComments();
          });
        }
      });
    };

    const replyComment = (idx) => {
      const comment = state.comments[idx];
      const args = {
        groupNum: comment.groupNum,
        orderNum: comment.orderNum,
        commentSeq: comment.commentSeq,
      };

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          ...args,
        },
        callback: `${component.name}.submitReply`
      });
    };

    const submitReply = (params) => {
      const args = {
        content: params.content,
        groupNum: params.groupNum,
        orderNum: params.orderNum,
      };

      http.post(`/api/maker/project/${modalParams.projectType}/${modalParams.projectSeq}/news/${modalParams.newsSeq}/reply`, args).then(() => {
        store.commit("setSwingMessage", "답변을 등록하였습니다.");
        loadComments();
      });
    };

    return {
      component
      , state
      , commentTextareaRef
      , modalParams
      , edit
      , remove
      , checkLogin
      , postComment
      , patchComment
      , editComment
      , removeComment
      , loadComments
      , replyComment
      , submitReply
    };
  },
});
</script>

<style lang="scss" scoped>
.news {
  > .header {
    background: #fff;
    padding: $px35 $px40;
    border-bottom: 1px solid #eee;

    > .top {
      position: relative;
      padding-right: $px100;

      > .title {
        margin-bottom: $px25;
      }

      > .acts {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        > .btn {
          padding: $px12 $px15;
          margin-left: $px4;
          margin-right: 0;
          border: 0;
        }
      }
    }

    > .desc {
      color: #666;
    }
  }

  > .wrapper {
    background: #fff;
    color: #000;
    padding: $px30 $px25;

    > .content {
      padding: 0 $px15;

      &::v-deep {
        img {
          height: auto;
        }
      }
    }

    .maker-info {
      margin: $px60 auto 0 auto;
    }

    .comments {
      margin-top: $px25;
      padding-top: $px25;

      .form {
        > .title {
          margin-bottom: $px15;
        }

        > .input {
          padding-right: $px115;
          position: relative;

          textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: $px100;
            font-size: $px14;
            padding: $px13;
            border-color: #ddd;
            resize: none;
          }

          button {
            position: absolute;
            right: 0;
            top: 0;
            width: $px100;
            height: $px100;
          }
        }
      }

      > .tight {
        margin-top: $px25;
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      border-radius: 0;

      span {
        vertical-align: middle;

        &.img {
          width: $px24;
          height: $px24;
          margin-left: $px8;
        }
      }

      i {
        margin-right: $px8;
        vertical-align: middle;
      }
    }
  }

  &[data-page=manage] {
    > .wrapper > .top {
      padding-right: $px140;
    }
  }

  &.skeleton {
    .header {
      .title > b {
        @include skeleton;
      }

      .acts a {
        @include skeleton;
      }

      .desc {
        > a, > span {
          @include skeleton;
        }
      }
    }

    > .wrapper {
      .acts {
        .btn, a {
          @include skeleton;
        }
      }

      .content {
        @include skeleton;
      }
    }

    .action .btn {
      @include skeleton;

      .img {
        visibility: hidden;
      }
    }
  }

  > .wrapper .comments.skeleton {
    .form > .title > span {
      @include skeleton;
    }

    .input {
      textarea,
      .btn {
        @include skeleton;
      }
    }
  }

  @media(max-width: 991px) {
    > .wrapper {
      padding: $px15;

      .comments {
        padding: 0 0 $px35;
      }
    }
  }
}
</style>